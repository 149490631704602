import React from 'react';
import Head from '../components/Head';
import Page from '../components/Page';
import { graphql, PageProps } from 'gatsby';
import { ContentfulPage, PageSections } from 'types';

type NotFoundQueryData = {
  notFoundPage: ContentfulPage & PageSections;
  site: { siteMetadata: { image: string } };
};

export default function NotFoundPage({ data, location }: PageProps<NotFoundQueryData, null>) {
  const title = data.notFoundPage.heading;
  const description = data.notFoundPage.plainDescription.plainDescription;
  const imageUrl =
    data.notFoundPage.image?.image?.gatsbyImageData?.images?.fallback?.src || data.site.siteMetadata.image;

  return (
    <>
      <Head location={location} title={title} description={description} image={imageUrl} />
      <Page sections={data.notFoundPage.sections} />
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    notFoundPage: contentfulPage(contentful_id: { eq: $id }) {
      ...Page
      ...PageSections
    }
    site {
      siteMetadata {
        image
      }
    }
  }
`;
